import { graphql } from 'gatsby'

import Helmet from 'react-helmet'

import React from "react"

import legalCSS from './legal.module.css'
export default ({ data }) => {
  const doc = data.markdownRemark
  //require('./legal.css')
  return (
    <>
      <Helmet title={doc.frontmatter.title}/>
      <div className={legalCSS.legal} dangerouslySetInnerHTML={{ __html: doc.html }} />
    </>
  )
}

export const query = graphql`
  query($slug: String!) {
    markdownRemark(fields: { slug: { eq: $slug } }) {
      html
      frontmatter {
        title
      }
    }
  }
`